import React, { useEffect, useState } from 'react'
import { DocumentIcon } from './icons/DocumentIcon'

type Props = {
  children: React.ReactNode
  uploadedFile: Attachment | undefined
  onChange: (file: File) => void
  requestStatus: RequestStatus
  id: string
  description: string
  acceptedFileTypes?: string
  errorMsg?: string
  required?: boolean
  attachmentErrors: AttachmentError[]
}

export const FileUpload = ({
  children,
  acceptedFileTypes,
  uploadedFile,
  onChange,
  requestStatus,
  id,
  description,
  errorMsg,
  required,
  attachmentErrors
}: Props) => {
  const [attachmentWithError, setAttachmentWithError] = useState<AttachmentError>()

  useEffect(() => {
    const [a] = attachmentErrors.filter(attachment => attachment.filename === id)
    setAttachmentWithError(a)
  }, [attachmentErrors])

  if (uploadedFile && !attachmentWithError) {
    return (
      <div>
        <div className="label">{description}</div>
        <div className="p-4 rounded-[var(--card-border-radius)] bg-bg-color flex items-center justify-between">
          <div className="flex space-x-2">
            <div>
              <DocumentIcon size={48} color="rgb(var(--tomra-mint-green-dark))" />
            </div>
            <div>
              <div>{!uploadedFile?.filename?.startsWith('http') ? uploadedFile?.filename : 'Logo'}</div>
              <div className="text-storm text-sm">
                {uploadedFile?.uploadedAt
                  ? `Uploaded ${new Date(uploadedFile.uploadedAt as string).toLocaleDateString()}`
                  : `Submitted ${new Date(uploadedFile?.savedAt as string).toLocaleDateString()}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {attachmentWithError ? (
        <div className="alert warning">Please re-upload {attachmentWithError.displayName}</div>
      ) : (
        <div>{children}</div>
      )}
      <label htmlFor={id} className="label">
        {description}
      </label>

      <div className="file-upload inverted">
        {requestStatus === 'loading' && (
          <div>
            <div className="loading-infinity w-16" />
          </div>
        )}

        <input
          id={id}
          aria-label={`Upload ${description} file`}
          name={id}
          type="file"
          disabled={requestStatus === 'loading'}
          accept={acceptedFileTypes || 'application/pdf'}
          onClick={(evt: any) => {
            // need to reset the input value, or else onChange
            // won't fire upon selecting the same image multiple times
            const element = evt.target as HTMLInputElement
            element.value = ''
          }}
          onChange={(evt: any) => {
            evt.target.files[0] && onChange(evt.target.files[0])
          }}
          required={required !== undefined ? required : !uploadedFile}
        />
      </div>

      {requestStatus === 'failed' || errorMsg ? (
        <span className="text-warm-red-dark ml-2">
          {errorMsg ? errorMsg : 'Something went wrong. Please try again.'}
        </span>
      ) : null}
    </div>
  )
}
