import React from 'react'

export const CampaignFormSkeleton = () => {
  return (
    <div className="max-w-screen-sm mx-auto my-md">
      <div className="card px-4 py-8">
        <div className="w-2/3 skeleton-heading mb-10" />

        <div className="flex flex-col items-start space-y-10">
          <div className="w-full flex flex-col items-start space-y-4">
            <div className="w-1/4 skeleton-paragraph" />
            <div className="w-full skeleton-paragraph h-10" />
          </div>
          <div className="w-full flex flex-col items-start space-y-4">
            <div className="w-1/4 skeleton-paragraph" />
            <div className="w-full skeleton-paragraph h-16" />
          </div>
          <div className="w-full flex flex-col items-start space-y-4">
            <div className="w-1/4 skeleton-paragraph" />
            <div className="w-full skeleton-paragraph h-10" />
          </div>
          <div className="w-full flex flex-col items-start space-y-4">
            <div className="w-1/4 skeleton-paragraph" />
            <div className="w-full skeleton-paragraph h-16" />
          </div>
        </div>
      </div>
    </div>
  )
}
