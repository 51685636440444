import React from 'react'
import { InfoIcon } from '../shared'

type Props = {
  content: string
}

export const InfoTooltip = ({ content }: Props) => (
  <div className="tooltip" data-tip={content}>
    <InfoIcon width="1rem" height="1rem" />
  </div>
)
