import React, { FunctionComponent } from 'react'
import { differenceInDays, formatDistanceStrict } from 'date-fns'

type Props = {
  startTime: string
  status: Campaign['status']
}

export const CampaignStart: FunctionComponent<Props> = ({ startTime, status }) => {
  const today = new Date()

  const campaignStartDate = new Date(startTime)
  const daysUntilCampaignStarts = differenceInDays(campaignStartDate, today)
  const stillPendingApproval = daysUntilCampaignStarts < 0 && status === 'NEW'
  const campaignIsRunning = daysUntilCampaignStarts < 0 && status === 'ACTIVE'

  if (stillPendingApproval) {
    return (
      <>
        <div className="text-sm text-storm dark:text-rock">Scheduled start</div>
        <div className="text-xl ">
          {formatDistanceStrict(campaignStartDate, new Date(), { addSuffix: true, unit: 'day' })}
        </div>
        <div className="text-sm">{campaignStartDate.toLocaleDateString()}</div>
      </>
    )
  }

  if (campaignIsRunning) {
    return (
      <>
        <div className="text-sm text-storm dark:text-rock">Days running</div>
        <div className="text-xl">{formatDistanceStrict(campaignStartDate, new Date(), { unit: 'day' })}</div>
        <div className="text-sm">Since {campaignStartDate.toLocaleDateString()}</div>
      </>
    )
  }

  return (
    <>
      <div className="text-sm text-storm dark:text-rock">Start</div>
      <div className="text-xl ">
        {formatDistanceStrict(campaignStartDate, new Date(), { addSuffix: true, unit: 'day' })}
      </div>
    </>
  )
}
