import React from 'react'
import { THEME } from '../../../lib'

export const TermsAndConditionsTASFull = () => {
  const { displayName } = THEME

  return (
    <div className="card m-6 p-6 max-w-screen-md mx-auto">
      <div className="prose">
        <h1>
          {displayName} app donation partner agreement ({THEME.stateShort})
        </h1>
      </div>
    </div>
  )
}
