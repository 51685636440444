import React, { FunctionComponent, useEffect } from 'react'
import * as d3 from 'd3'
import { currencyFormatter } from '../../../../lib/campaign'

export const CampaignsRaisedChart: FunctionComponent<{ campaignsWithStatistics: CampaignWithStatistics[] }> = ({
  campaignsWithStatistics
}) => {
  useEffect(() => {
    const container = d3.select('#chart')

    const totalDonatedScale = d3.scaleLinear().range([0, 600]).domain([0, 10000])

    const join = container.selectAll('div').data(campaignsWithStatistics)

    const campaign = join.enter().append('div').attr('class', 'py-md')

    campaign
      .append('h3')
      .text(({ campaign }) => campaign.name)
      .attr('class', 'text-storm dark:text-dust')

    campaign
      .append('div')
      .text(({ statistics }) => `AUD ${currencyFormatter.format(statistics.totalDonated)}`)
      .attr(
        'class',
        'bg-[rgba(167,220,205,1)] dark:bg-[rgba(167,220,205,.3)]  text-right text-sm px-2 py-2 rounded-r-full my-sm'
      )
      .style('width', ({ statistics }) => `${totalDonatedScale(statistics.totalDonated)}px`)
      .style('white-space', 'nowrap')

    return () => {
      join.exit().remove()
    }
  }, [])

  return (
    <div>
      <div className="flex items-end justify-between">
        <h2 className="text-lg text-storm dark:text-dust mb-6">Past fundraising campaigns</h2>

        <div className="flex space-x-6">
          <div className="flex items-center space-x-2">
            <div className="w-6 h-6 rounded-full bg-[rgba(167,220,205,1)] dark:bg-[rgba(167,220,205,.3)]" />
            <div className="text-storm dark:text-dust">Money raised</div>
          </div>
        </div>
      </div>

      <div id="chart" />
    </div>
  )
}
