import { addDays, subYears } from 'date-fns'
import { object, string, date, ValidationError, boolean } from 'yup'
import {
  benefitToReadableString,
  dgrStatusToReadableString,
  geoFootprintToReadableString,
  legalStructureToReadableString
} from '../components/shared/forms/enumsToReadableStrings'

export const attachmentsTypes = [
  'IncorporationFile',
  'ABNDocument',
  'ParentOrganizationLetter',
  'ProofOfBankDetails',
  'OrganizationLogo'
]

export function validateCharityForm(changes: CharityFormState) {
  const validateTos = validateCharityPart(ValidTOS, changes)
  const validateEligibility = validateCharityPart(ValidEligibility, changes)
  const validateDetailsOfYourOrganization = validateCharityPart(ValidDetailsOfYourOrganisation, changes)
  const validateLocationAndOperationalFootprint = validateCharityPart(ValidLocationAndOperationalFootprint, changes)
  const validateAboutYourOrganisation = validateCharityPart(ValidAboutYourOrganisation, changes)
  const validateFinance = validateCharityPart(ValidFinance, changes)
  const validateContact = validateCharityPart(ValidContact, changes)

  return {
    ...(validateTos.length > 0 && { 'Terms And Conditions': validateTos }),
    ...(validateEligibility.length > 0 && { Eligibility: validateEligibility }),
    ...(validateDetailsOfYourOrganization.length > 0 && {
      'Details Of Your Organisation': validateDetailsOfYourOrganization
    }),
    ...(validateLocationAndOperationalFootprint.length > 0 && {
      'Location And Operational Footprint': validateLocationAndOperationalFootprint
    }),
    ...(validateAboutYourOrganisation.length > 0 && {
      'About Your Organisation': validateAboutYourOrganisation
    }),
    ...(validateFinance.length > 0 && {
      Finance: validateFinance
    }),
    ...(validateContact.length > 0 && {
      Contact: validateContact
    })
  }
}

export const ValidTOS = object({
  hasAcceptedTos: boolean().when(
    'uiFields_OrganizationLogo_filename',
    ([uiFields_OrganizationLogo_filename], schema) => {
      return !uiFields_OrganizationLogo_filename
        ? schema
            .required('You must accept the terms and conditions')
            .oneOf([true], 'You must accept the terms and conditions')
        : schema
    }
  )
})

export const ValidEligibility = object({
  charity_organization_registeredState: string()
    .oneOf(['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT', 'ACT'], 'Registered in state must be selected')
    .required('Registered in state must be selected'),
  charity_organization_legalStructure: string()
    .oneOf(
      [...Object.keys(legalStructureToReadableString.NSW), ...Object.keys(legalStructureToReadableString.VIC)],
      'Legal structure must be selected'
    )
    .required('Legal structure must be selected'),
  charity_organization_legalStructureDetails: string().when(
    'charity_organization_legalStructure',
    ([legalStructure], schema) => {
      return legalStructure === 'OTHER' ? schema.required() : schema
    }
  ),
  charity_organization_establishedDate: date()
    .max(addDays(subYears(new Date(), 2), 1), 'Established date must be at earlier than 2 years ago')
    .required(),
  uiFields_operatesInState: boolean()
    .oneOf([true], 'You must confirm that you currently operate in this state')
    .required('You must confirm that you currently operate in this state')
})

export const ValidDetailsOfYourOrganisation = object({
  charity_organization_name: string().required(),
  charity_organization_incorporationNumber: string().when(
    'charity_organization_legalStructure',
    ([legalStructure], schema) => {
      return legalStructure === 'NON_PROFIT_UNDER_AIA2009'
        ? schema.required('Incorporation number is a required field for your selected legal structure')
        : schema
    }
  ),
  charity_organization_localGroupName: string().when('uiFields_abnBelongsToParent', ([abnBelongsToParent], schema) => {
    return abnBelongsToParent
      ? schema.required('Local group name is a required field if the ABN belongs to your parent organisation')
      : schema
  }),
  uiFields_IncorporationFile_filename: string().when(
    'charity_organization_legalStructure',
    ([legalStructure], schema) => {
      return legalStructure === 'NON_PROFIT_UNDER_AIA2009'
        ? schema.required('Incorporation file must be uploaded for your selected legal structure')
        : schema
    }
  ),
  uiFields_abnBelongsToParent: boolean().optional(),
  uiFields_ABNDocument_filename: string().required('A copy of your ABN certificate must be uploaded'),
  uiFields_ParentOrganizationLetter_filename: string().when(
    'uiFields_abnBelongsToParent',
    ([abnBelongsToParent], schema) => {
      return abnBelongsToParent
        ? schema.required(
            'A parent organisation letter must be uploaded if the ABN belongs to your parent organisation'
          )
        : schema
    }
  )
})

export const ValidLocationAndOperationalFootprint = object({
  charity_organization_geoFootprint: string()
    .oneOf(Object.keys(geoFootprintToReadableString), 'Geographical footprint must be selected')
    .required('Geographical footprint must be selected'),
  charity_organization_streetAddress: string().max(35).required(),
  charity_organization_suburb: string().optional(),
  charity_organization_postCode: string().required()
})

export const ValidAboutYourOrganisation = object({
  charity_organization_displayName: string().required(),
  charity_organization_websiteUrl: string().required(),
  charity_organization_benefit: string()
    .oneOf(Object.keys(benefitToReadableString), 'Main community benefit must be selected')
    .required('Main community benefit must be selected'),
  charity_organization_description: string().max(500).required(),
  uiFields_OrganizationLogo_filename: string().required('A logo must be uploaded')
})

export const ValidFinance = object({
  charity_bankingData_accountName: string().required(),
  charity_bankingData_accountBsb: string()
    .min(6, 'BSB must be 6 digits')
    .matches(new RegExp(`^\\d{6}$`), 'BSB can only contain 6 digits, and must not include any spaces')
    .required(),
  charity_bankingData_accountNumber: string()
    .matches(new RegExp(`^\\d{1,9}$`), 'Account number can only contain digits, and must not include any spaces')
    .min(1)
    .max(9)
    .required(),
  uiFields_ProofOfBankDetails_filename: string().required('Proof of bank details must be uploaded'),
  charity_bankingData_dgrStatus: string()
    .oneOf(Object.keys(dgrStatusToReadableString), 'Deductible Gift Registration (DGR) status must be selected')
    .required('Deductible Gift Registration (DGR) status must be selected'),
  charity_subDgrStatus: string().when('charity_bankingData_dgrStatus', ([dgrStatus], schema) => {
    if (dgrStatus === 'RESTRICTED') {
      return schema.required('Please confirm how the funds will be spent')
    }

    return schema
  })
})

export const ValidContact = object({
  charity_primaryContact_firstName: string().required(),
  charity_primaryContact_lastName: string().required(),
  charity_primaryContact_position: string().required(),
  charity_primaryContact_email: string().email().required(),
  charity_primaryContact_phone: string().required(),
  charity_secondaryContact_firstName: string().nullable(),
  charity_secondaryContact_lastName: string().nullable(),
  charity_secondaryContact_position: string().nullable(),
  charity_secondaryContact_email: string().email().nullable(),
  charity_secondaryContact_phone: string().nullable()
})

const charityPathMap: { [key: string]: string } = {
  charity_organization_legalStructure: 'Legal structure',
  charity_organization_legalStructureDetails: 'Legal structure details',
  charity_organization_establishedDate: 'Established date',
  charity_organization_name: 'Organisation name',
  charity_organization_geoFootprint: 'Geographical footprint',
  charity_organization_streetAddress: 'Street address',
  charity_organization_suburb: 'Suburb',
  charity_organization_postCode: 'Postcode',
  charity_organization_displayName: 'Display name',
  charity_organization_websiteUrl: 'Website URL',
  charity_organization_description: 'Description',
  charity_bankingData_accountName: 'Account name',
  charity_bankingData_accountBsb: 'BSB',
  charity_bankingData_accountNumber: 'Account number',
  charity_bankingData_dgrStatus: 'DGR status',
  charity_primaryContact_firstName: 'First name',
  charity_primaryContact_lastName: 'Last name',
  charity_primaryContact_position: 'Position',
  charity_primaryContact_email: 'Email',
  charity_primaryContact_phone: 'Phone',
  charity_secondaryContact_firstName: 'Secondary first name',
  charity_secondaryContact_lastName: 'Secondary last name',
  charity_secondaryContact_position: 'Secondary position',
  charity_secondaryContact_email: 'Secondary email',
  charity_secondaryContact_phone: 'Secondary phone'
}

export function validateCharityPart(schema: any, object: Partial<CharityFormState>) {
  try {
    schema.validateSync(flattenObject(object), { abortEarly: false })
    return []
  } catch (error) {
    if (!error) {
      return []
    }

    return (error as ValidationError).inner.map(error => {
      const { path, type, message } = error

      const mappedPath = charityPathMap[path as string] || path

      return {
        path,
        type,
        message: message.replace(path as string, mappedPath as string)
      }
    })
  }
}

export function parseAttachmentErrors(errorMessage: string) {
  const attachmentDisplayName: { [key: string]: string } = {
    IncorporationFile: 'Incorporation file',
    ABNDocument: 'Copy of your ABN certificate',
    ParentOrganizationLetter: 'Letter/statutory declaration from parent organisation',
    ProofOfBankDetails: 'Proof of bank details'
  }

  const attachmentSections: { [key: string]: string } = {
    IncorporationFile: 'Details of your organisation',
    ABNDocument: 'Details of your organisation',
    ParentOrganizationLetter: 'Details of your organisation',
    ProofOfBankDetails: 'Finance'
  }

  return attachmentsTypes.reduce((acc, attachment) => {
    if (errorMessage.includes(attachment)) {
      return [
        ...acc,
        {
          filename: attachment,
          displayName: attachmentDisplayName[attachment],
          section: attachmentSections[attachment]
        }
      ]
    }

    return acc
  }, [] as AttachmentError[])
}

export function checkIfBankingDataHasChanged(originalBankingData: BankingData | null, bankingData: BankingData) {
  const accountNameHasChanged = !!(originalBankingData && bankingData.accountName !== originalBankingData?.accountName)
  const bsbHasChanged = !!(originalBankingData && bankingData.accountBsb !== originalBankingData?.accountBsb)
  const bankAccountNumberHasChanged = !!(
    originalBankingData && bankingData.accountNumber !== originalBankingData?.accountNumber
  )

  return bankAccountNumberHasChanged || bsbHasChanged || accountNameHasChanged
}

// @ts-ignore
export function flattenObject(object: object, parentKey = '', result: { [key: string]: unknown } = {}) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const newKey = `${parentKey}${parentKey ? '_' : ''}${key}`

    if (typeof value === 'object' && value !== null && !Array.isArray(value) && !(value instanceof Date)) {
      return { ...acc, ...flattenObject(value, newKey) }
    } else if (!shouldFilterKey(key)) {
      return { ...acc, [newKey]: value }
    } else {
      return acc
    }
  }, result)
}

function shouldFilterKey(key: string): boolean {
  const ignoreKeys = ['id', 'status', 'updatedAt', 'createdAt', 'createdBy', 'updatedBy', 'attachments']
  return ignoreKeys.includes(key)
}
