import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  isOpen: boolean
  children: React.ReactNode
}

export const Modal = ({ isOpen, children }: Props) => {
  return ReactDOM.createPortal(
    <div className={`modal ${isOpen ? 'open !top-20' : ''}`}>{children}</div>,
    document.getElementById('root') as HTMLDivElement
  )
}
