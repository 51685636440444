import { APP_CHECK_KEY, FIREBASE_CONFIG } from './lib'
import { initializeApp } from 'firebase/app'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAuth, connectAuthEmulator, initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'

// @ts-ignore
const isE2E = window.Cypress
const useAppCheckDebugToken = import.meta.env.MODE === 'development' || isE2E
const appCheckToken = isE2E
  ? Cypress.env('CYPRESS_APP_CHECK_TOKEN') || process.env.APP_CHECK_TOKEN
  : process.env.APP_CHECK_TOKEN || false

const app = initializeApp(FIREBASE_CONFIG)
const storage = getStorage(app)
const auth = isE2E ? initializeAuth(app, { persistence: browserLocalPersistence }) : getAuth(app)
const db = getFirestore(app)

if (isE2E) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectStorageEmulator(storage, '127.0.0.1', 9199)
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

if (useAppCheckDebugToken && !appCheckToken) {
  //eslint-disable-next-line
  console.warn('No App Check token found. Please set the environment variable.')
}
// @ts-ignore
self.FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckToken
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(APP_CHECK_KEY),
  isTokenAutoRefreshEnabled: true
})

export { app, storage, auth, db, appCheck }
