import React, { FunctionComponent } from 'react'
import { currencyFormatter } from '../../../../lib/campaign'

export const CampaignsTotalRaised: FunctionComponent<{ campaignsWithStatistics: CampaignWithStatistics[] }> = ({
  campaignsWithStatistics
}) => {
  const totalRaised = campaignsWithStatistics.reduce((acc, { statistics }) => acc + statistics.totalDonated, 0)

  return (
    <>
      <div className="card p-4 overflow-hidden">
        <div className="text-sm">Donations</div>
        <div className="flex items-end justify-center space-x-2 pt-6 pb-2">
          <div className="text-storm dark:text-dust">AUD</div>
          <div className="font-bold text-[3rem] text-storm -mb-[0.6rem]">{currencyFormatter.format(totalRaised)}</div>
        </div>
      </div>
      <div className="card-footer">{totalRaised > 0 ? 'has been raised from the start!' : ''}</div>
    </>
  )
}
