import { useReducer } from 'react'
import { campaignInit } from '../initialData'

type CampaignFormFieldAction = {
  type: 'UPDATE_FIELDS'
  payload: Partial<Campaign>
}

const campaignFieldsReducer = (state: Campaign, { type, payload }: CampaignFormFieldAction): Campaign => {
  switch (type) {
    case 'UPDATE_FIELDS':
      return {
        ...state,
        ...payload
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export const useCampaignFormFields = (campaign: Campaign | null) => {
  const initialState = campaign || campaignInit

  const [state, dispatch] = useReducer(campaignFieldsReducer, initialState)

  function updateField(payload: Partial<Campaign>) {
    dispatch({ type: 'UPDATE_FIELDS', payload })
  }

  return {
    formValues: state,
    updateField
  }
}
