import React, { FunctionComponent, useState } from 'react'

type Props = {
  imageUrl: string
  altText: string
}

export const Image: FunctionComponent<Props> = ({ imageUrl, altText }) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  return (
    <div className="relative bg-fog w-32 h-32 rounded-full overflow-hidden">
      {!hasLoaded && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="loading-infinity w-10" />
        </div>
      )}

      <img
        onLoad={() => setHasLoaded(true)}
        src={imageUrl}
        className={`transition object-cover ${hasLoaded ? 'opacity-100' : 'opacity-0'}`}
        alt={altText}
      />
    </div>
  )
}
