export function findStateDifference(originalObject: any, changedObject: any, removeEmptyFields = false) {
  const ignoreKeys = ['updatedAt', 'createdAt', 'logoUrl', 'campaignCode', 'id', 'status', 'campaignId', 'charityId']

  if (removeEmptyFields) {
    if (!originalObject && !changedObject) {
      return {}
    }

    if (!originalObject && isObject(changedObject)) {
      return Object.keys(changedObject).reduce((acc, key) => {
        if (ignoreKeys.includes(key)) {
          return acc
        }

        if (isObject(changedObject[key])) {
          const diff = findStateDifference(null, changedObject[key], true)

          if (Object.keys(diff).length > 0) {
            acc[key] = diff
          }
        } else if (changedObject[key]) {
          acc[key] = changedObject[key]
        }

        return acc
      }, {} as any)
    }
  }

  if (!isObject(originalObject) || !isObject(changedObject)) {
    return changedObject
  }

  return Object.keys(originalObject).reduce((acc, key) => {
    const original = originalObject[key]
    const changes = changedObject[key]

    if (ignoreKeys.includes(key)) {
      return acc
    }

    if (isObject(original) && isObject(changes)) {
      const diff = findStateDifference(original, changes)

      if (Object.keys(diff).length > 0) {
        acc[key] = diff
      }
    } else if (original !== changes && changes) {
      acc[key] = changes
    }

    return acc
  }, {} as any)
}

export function mergeStateObjects(obj1: any, obj2: any) {
  const mergedObject = { ...obj1 }

  for (const key in obj2) {
    if (Object.hasOwn(obj2, key)) {
      if (isObject(obj2[key]) && obj2[key] !== null) {
        mergedObject[key] = mergeStateObjects(mergedObject[key] || {}, obj2[key])
      } else if (obj2[key] !== null) {
        mergedObject[key] = obj2[key]
      }
    }
  }

  return mergedObject
}

const isObject = (value: any) => typeof value === 'object' && value !== null && value !== undefined
