import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { THEME } from '../lib'

const DRAFT_SCHEMA_VERSION = 1

const { name } = THEME

export async function updateDraft(adminId: string, changes: Partial<Draft>) {
  await setDoc(doc(db, name, adminId), changes, { merge: true })
  const draftSnapshot = await getDoc(doc(db, name, adminId))
  return draftSnapshot.data() as Draft
}

export async function getOrCreateDraft(adminId: string, charity: Charity | null): Promise<Draft> {
  const draftDoc = doc(db, name, adminId)
  const snapshot = await getDoc(draftDoc)

  if (snapshot.exists()) {
    const data = snapshot.data()

    if (data.schemaVersion !== DRAFT_SCHEMA_VERSION) {
      await deleteDraft(adminId)
      return createDraft(adminId, charity)
    }

    return data as Draft
  }

  return await createDraft(adminId, charity)
}

export async function createDraft(adminId: string, charity: Charity | null): Promise<Draft> {
  const draft: Draft = {
    adminId,
    createdAt: (charity?.createdAt as string) || new Date().toISOString(),
    updatedAt: (charity?.updatedAt as string) || new Date().toISOString(),
    schemaVersion: DRAFT_SCHEMA_VERSION
  }

  const draftDoc = doc(db, name, adminId)
  await setDoc(draftDoc, draft)
  const draftSnapshot = await getDoc(draftDoc)
  return draftSnapshot.data() as Draft
}

export function deleteDraft(adminId: string) {
  return deleteDoc(doc(db, name, adminId))
}
