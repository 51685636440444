import React from 'react'

export const TermsAndConditionsVICShort = () => {
  return (
    <>
      <p>
        Organisations applying to become <b>CDS Vic West app Donation Partners</b> must meet the following criteria:
      </p>
      <ol className="list-lowercase">
        <li>
          Be registered as a Donation Partner with the Scheme Coordinator VIC Return and have a valid Donation Partner
          ID
        </li>
        <li>
          fulfill at least one of the following eligibility criteria:
          <ol className="list-[upper-roman]">
            <li>
              be currently registered as a charity in VIC (unless exempted under the Fundraising Act 1998 (Vic)); or
            </li>
            <li>have Deductible Gift Status with the Australian Taxation Office; or</li>
            <li>
              be a registered school within the meaning of the Education and Training Reform Act 2006 (whether primary
              or secondary, government or private); or if a sporting community, educational or environmental body, be
              incorporated as a not-for- profit organisation (either under the Associations Incorporation Reform Act
              2012 or as a company limited by guarantee) and further, if a sporting club, demonstrate an affiliation
              with a recognised state sporting organisation.
            </li>
          </ol>
        </li>
        <li>
          be willing, with reasonable endeavours, to use its own networks and resources to promote awareness of its
          presence on the CDS Vic West app to mutual advantage;
        </li>
        <li>be reputable and of good standing in the community;</li>
        <li>
          not be a political party or an organisation associated with current political issues or political activism;
        </li>
        <li>not be associated with gambling, wagering, tobacco or smoking as a primary source of their income;</li>
        <li>not be associated with any illegal or criminal activity; and,</li>
        <li>
          not be associated with any activity or publicity that could bring the CDS Vic scheme or any of its
          stakeholders into controversy or disrepute.
        </li>
      </ol>
    </>
  )
}
