import React, { useEffect } from 'react'
import anime from 'animejs'

export const AnimatedClouds = () => {
  useEffect(() => {
    anime({
      targets: '.cloud1',
      right: '100%',
      duration: 100000,
      easing: 'linear',
      loop: true
    })

    anime({
      targets: '.cloud2',
      right: '100%',
      duration: 90000,
      easing: 'linear',
      loop: true
    })

    anime({
      targets: '.cloud3',
      right: '100%',
      duration: 95000,
      easing: 'linear',
      loop: true
    })
  }, [])

  return (
    <>
      <svg
        className="cloud1 absolute z-0 -right-[50%]"
        width="1116"
        height="306"
        viewBox="0 0 1116 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M603.913 220.315H544.087C541.748 218.873 539.909 216.698 538.888 214.112C538.317 212.823 538 211.396 538 209.896C538 209.821 538.001 209.745 538.002 209.67C538.001 209.587 538 209.504 538 209.42C538 202.357 543.726 196.631 550.789 196.631C553.234 196.631 555.518 197.316 557.461 198.506C560.956 193.372 566.847 190 573.527 190C580.392 190 586.425 193.562 589.879 198.939C591.955 197.484 594.483 196.631 597.21 196.631C604.274 196.631 610 202.357 610 209.42C610 209.502 609.999 209.584 609.997 209.665C609.999 209.742 610 209.819 610 209.896C610 211.398 609.682 212.826 609.11 214.116C608.089 216.701 606.251 218.873 603.913 220.315Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M929.729 122.209H858.271C857.361 121.648 856.514 120.994 855.744 120.261C852.877 118.275 851 114.963 851 111.212V109.196V108.317C851 106.641 851.375 105.053 852.045 103.632C854.27 97.9462 859.803 93.9196 866.276 93.9196C869.196 93.9196 871.925 94.7388 874.245 96.1599C878.419 90.0274 885.457 86 893.435 86C901.635 86 908.841 90.2548 912.966 96.6772C915.446 94.9394 918.466 93.9196 921.724 93.9196C928.197 93.9196 933.73 97.9462 935.955 103.631C936.625 105.053 937 106.641 937 108.317V109.196V111.212C937 114.963 935.123 118.275 932.256 120.261C931.486 120.994 930.639 121.648 929.729 122.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.7292 137.209H7.27076C6.36079 136.648 5.5141 135.994 4.7439 135.261C1.87744 133.275 0 129.963 0 126.212V124.196V123.317C0 121.641 0.374764 120.053 1.04506 118.632C3.26967 112.946 8.80275 108.92 15.2763 108.92C18.1962 108.92 20.9248 109.739 23.2448 111.16C27.4193 105.027 34.4566 101 42.4346 101C50.6347 101 57.8411 105.255 61.9665 111.677C64.4462 109.939 67.4658 108.92 70.7237 108.92C77.1972 108.92 82.7303 112.946 84.9549 118.631C85.6252 120.053 86 121.641 86 123.317V124.196V126.212C86 129.963 84.1226 133.275 81.2561 135.261C80.4859 135.994 79.6392 136.648 78.7292 137.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M357.506 305.03C357.144 304.941 356.765 304.895 356.375 304.895H325.752C325.362 304.895 324.983 304.941 324.621 305.03C323.209 304.23 322.096 302.963 321.495 301.438C321.177 300.72 321 299.925 321 299.088C321 299.046 321 299.004 321.001 298.963C321 298.916 321 298.87 321 298.823C321 294.887 324.191 291.695 328.128 291.695C329.49 291.695 330.763 292.078 331.846 292.741C333.794 289.879 337.077 288 340.8 288C344.626 288 347.988 289.985 349.913 292.982C351.07 292.171 352.479 291.695 353.999 291.695C357.936 291.695 361.127 294.887 361.127 298.823C361.127 298.869 361.126 298.914 361.126 298.96C361.126 299.002 361.127 299.045 361.127 299.088C361.127 299.926 360.95 300.721 360.631 301.44C360.029 302.964 358.917 304.231 357.506 305.03Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1111.51 33.0299C1111.14 32.9414 1110.76 32.8945 1110.38 32.8945H1079.75C1079.36 32.8945 1078.98 32.9415 1078.62 33.03C1077.21 32.2304 1076.1 30.9628 1075.49 29.438C1075.18 28.7196 1075 27.9246 1075 27.0884C1075 27.0463 1075 27.0044 1075 26.9626C1075 26.9162 1075 26.8697 1075 26.8232C1075 22.8866 1078.19 19.6954 1082.13 19.6954C1083.49 19.6954 1084.76 20.0776 1085.85 20.7406C1087.79 17.8792 1091.08 16 1094.8 16C1098.63 16 1101.99 17.9853 1103.91 20.982C1105.07 20.1712 1106.48 19.6954 1108 19.6954C1111.94 19.6954 1115.13 22.8866 1115.13 26.8232C1115.13 26.8688 1115.13 26.9143 1115.13 26.9596C1115.13 27.0025 1115.13 27.0454 1115.13 27.0884C1115.13 27.9256 1114.95 28.7214 1114.63 29.4404C1114.03 30.9641 1112.92 32.2308 1111.51 33.0299Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M344.572 24.4214H345.297C345.298 24.4222 345.299 24.4229 345.3 24.4237H355C355.329 24.4237 355.652 24.4502 355.967 24.5012C357.923 23.335 359.459 21.5393 360.297 19.3938C360.749 18.3631 361 17.2242 361 16.0267C361 15.9681 360.999 15.9098 360.998 15.8515C361 15.7833 361 15.7148 361 15.6463C361 9.95628 356.388 5.34364 350.698 5.34364C348.501 5.34364 346.465 6.03116 344.793 7.20276C342.011 2.87035 337.15 0 331.619 0C326.237 0 321.491 2.71691 318.675 6.85384C317.111 5.89585 315.271 5.34364 313.303 5.34364C307.613 5.34364 303 9.95628 303 15.6463C303 15.7131 303.001 15.7797 303.002 15.8462C303.001 15.9062 303 15.9664 303 16.0267C303 17.2259 303.251 18.3664 303.705 19.3983C304.543 21.5418 306.079 23.3358 308.033 24.5012C308.348 24.4502 308.671 24.4237 309 24.4237H344.57C344.57 24.4229 344.571 24.4222 344.572 24.4214Z"
          fill="white"
        />
      </svg>

      <svg
        className="cloud2 absolute -right-0 top-[6rem]"
        width="1116"
        height="306"
        viewBox="0 0 1116 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M603.913 220.315H544.087C541.748 218.873 539.909 216.698 538.888 214.112C538.317 212.823 538 211.396 538 209.896C538 209.821 538.001 209.745 538.002 209.67C538.001 209.587 538 209.504 538 209.42C538 202.357 543.726 196.631 550.789 196.631C553.234 196.631 555.518 197.316 557.461 198.506C560.956 193.372 566.847 190 573.527 190C580.392 190 586.425 193.562 589.879 198.939C591.955 197.484 594.483 196.631 597.21 196.631C604.274 196.631 610 202.357 610 209.42C610 209.502 609.999 209.584 609.997 209.665C609.999 209.742 610 209.819 610 209.896C610 211.398 609.682 212.826 609.11 214.116C608.089 216.701 606.251 218.873 603.913 220.315Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M929.729 122.209H858.271C857.361 121.648 856.514 120.994 855.744 120.261C852.877 118.275 851 114.963 851 111.212V109.196V108.317C851 106.641 851.375 105.053 852.045 103.632C854.27 97.9462 859.803 93.9196 866.276 93.9196C869.196 93.9196 871.925 94.7388 874.245 96.1599C878.419 90.0274 885.457 86 893.435 86C901.635 86 908.841 90.2548 912.966 96.6772C915.446 94.9394 918.466 93.9196 921.724 93.9196C928.197 93.9196 933.73 97.9462 935.955 103.631C936.625 105.053 937 106.641 937 108.317V109.196V111.212C937 114.963 935.123 118.275 932.256 120.261C931.486 120.994 930.639 121.648 929.729 122.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.7292 137.209H7.27076C6.36079 136.648 5.5141 135.994 4.7439 135.261C1.87744 133.275 0 129.963 0 126.212V124.196V123.317C0 121.641 0.374764 120.053 1.04506 118.632C3.26967 112.946 8.80275 108.92 15.2763 108.92C18.1962 108.92 20.9248 109.739 23.2448 111.16C27.4193 105.027 34.4566 101 42.4346 101C50.6347 101 57.8411 105.255 61.9665 111.677C64.4462 109.939 67.4658 108.92 70.7237 108.92C77.1972 108.92 82.7303 112.946 84.9549 118.631C85.6252 120.053 86 121.641 86 123.317V124.196V126.212C86 129.963 84.1226 133.275 81.2561 135.261C80.4859 135.994 79.6392 136.648 78.7292 137.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M357.506 305.03C357.144 304.941 356.765 304.895 356.375 304.895H325.752C325.362 304.895 324.983 304.941 324.621 305.03C323.209 304.23 322.096 302.963 321.495 301.438C321.177 300.72 321 299.925 321 299.088C321 299.046 321 299.004 321.001 298.963C321 298.916 321 298.87 321 298.823C321 294.887 324.191 291.695 328.128 291.695C329.49 291.695 330.763 292.078 331.846 292.741C333.794 289.879 337.077 288 340.8 288C344.626 288 347.988 289.985 349.913 292.982C351.07 292.171 352.479 291.695 353.999 291.695C357.936 291.695 361.127 294.887 361.127 298.823C361.127 298.869 361.126 298.914 361.126 298.96C361.126 299.002 361.127 299.045 361.127 299.088C361.127 299.926 360.95 300.721 360.631 301.44C360.029 302.964 358.917 304.231 357.506 305.03Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1111.51 33.0299C1111.14 32.9414 1110.76 32.8945 1110.38 32.8945H1079.75C1079.36 32.8945 1078.98 32.9415 1078.62 33.03C1077.21 32.2304 1076.1 30.9628 1075.49 29.438C1075.18 28.7196 1075 27.9246 1075 27.0884C1075 27.0463 1075 27.0044 1075 26.9626C1075 26.9162 1075 26.8697 1075 26.8232C1075 22.8866 1078.19 19.6954 1082.13 19.6954C1083.49 19.6954 1084.76 20.0776 1085.85 20.7406C1087.79 17.8792 1091.08 16 1094.8 16C1098.63 16 1101.99 17.9853 1103.91 20.982C1105.07 20.1712 1106.48 19.6954 1108 19.6954C1111.94 19.6954 1115.13 22.8866 1115.13 26.8232C1115.13 26.8688 1115.13 26.9143 1115.13 26.9596C1115.13 27.0025 1115.13 27.0454 1115.13 27.0884C1115.13 27.9256 1114.95 28.7214 1114.63 29.4404C1114.03 30.9641 1112.92 32.2308 1111.51 33.0299Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M344.572 24.4214H345.297C345.298 24.4222 345.299 24.4229 345.3 24.4237H355C355.329 24.4237 355.652 24.4502 355.967 24.5012C357.923 23.335 359.459 21.5393 360.297 19.3938C360.749 18.3631 361 17.2242 361 16.0267C361 15.9681 360.999 15.9098 360.998 15.8515C361 15.7833 361 15.7148 361 15.6463C361 9.95628 356.388 5.34364 350.698 5.34364C348.501 5.34364 346.465 6.03116 344.793 7.20276C342.011 2.87035 337.15 0 331.619 0C326.237 0 321.491 2.71691 318.675 6.85384C317.111 5.89585 315.271 5.34364 313.303 5.34364C307.613 5.34364 303 9.95628 303 15.6463C303 15.7131 303.001 15.7797 303.002 15.8462C303.001 15.9062 303 15.9664 303 16.0267C303 17.2259 303.251 18.3664 303.705 19.3983C304.543 21.5418 306.079 23.3358 308.033 24.5012C308.348 24.4502 308.671 24.4237 309 24.4237H344.57C344.57 24.4229 344.571 24.4222 344.572 24.4214Z"
          fill="white"
        />
      </svg>

      <svg
        className="cloud3 absolute"
        width="1116"
        height="306"
        viewBox="0 0 1116 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M603.913 220.315H544.087C541.748 218.873 539.909 216.698 538.888 214.112C538.317 212.823 538 211.396 538 209.896C538 209.821 538.001 209.745 538.002 209.67C538.001 209.587 538 209.504 538 209.42C538 202.357 543.726 196.631 550.789 196.631C553.234 196.631 555.518 197.316 557.461 198.506C560.956 193.372 566.847 190 573.527 190C580.392 190 586.425 193.562 589.879 198.939C591.955 197.484 594.483 196.631 597.21 196.631C604.274 196.631 610 202.357 610 209.42C610 209.502 609.999 209.584 609.997 209.665C609.999 209.742 610 209.819 610 209.896C610 211.398 609.682 212.826 609.11 214.116C608.089 216.701 606.251 218.873 603.913 220.315Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M929.729 122.209H858.271C857.361 121.648 856.514 120.994 855.744 120.261C852.877 118.275 851 114.963 851 111.212V109.196V108.317C851 106.641 851.375 105.053 852.045 103.632C854.27 97.9462 859.803 93.9196 866.276 93.9196C869.196 93.9196 871.925 94.7388 874.245 96.1599C878.419 90.0274 885.457 86 893.435 86C901.635 86 908.841 90.2548 912.966 96.6772C915.446 94.9394 918.466 93.9196 921.724 93.9196C928.197 93.9196 933.73 97.9462 935.955 103.631C936.625 105.053 937 106.641 937 108.317V109.196V111.212C937 114.963 935.123 118.275 932.256 120.261C931.486 120.994 930.639 121.648 929.729 122.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.7292 137.209H7.27076C6.36079 136.648 5.5141 135.994 4.7439 135.261C1.87744 133.275 0 129.963 0 126.212V124.196V123.317C0 121.641 0.374764 120.053 1.04506 118.632C3.26967 112.946 8.80275 108.92 15.2763 108.92C18.1962 108.92 20.9248 109.739 23.2448 111.16C27.4193 105.027 34.4566 101 42.4346 101C50.6347 101 57.8411 105.255 61.9665 111.677C64.4462 109.939 67.4658 108.92 70.7237 108.92C77.1972 108.92 82.7303 112.946 84.9549 118.631C85.6252 120.053 86 121.641 86 123.317V124.196V126.212C86 129.963 84.1226 133.275 81.2561 135.261C80.4859 135.994 79.6392 136.648 78.7292 137.209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M357.506 305.03C357.144 304.941 356.765 304.895 356.375 304.895H325.752C325.362 304.895 324.983 304.941 324.621 305.03C323.209 304.23 322.096 302.963 321.495 301.438C321.177 300.72 321 299.925 321 299.088C321 299.046 321 299.004 321.001 298.963C321 298.916 321 298.87 321 298.823C321 294.887 324.191 291.695 328.128 291.695C329.49 291.695 330.763 292.078 331.846 292.741C333.794 289.879 337.077 288 340.8 288C344.626 288 347.988 289.985 349.913 292.982C351.07 292.171 352.479 291.695 353.999 291.695C357.936 291.695 361.127 294.887 361.127 298.823C361.127 298.869 361.126 298.914 361.126 298.96C361.126 299.002 361.127 299.045 361.127 299.088C361.127 299.926 360.95 300.721 360.631 301.44C360.029 302.964 358.917 304.231 357.506 305.03Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1111.51 33.0299C1111.14 32.9414 1110.76 32.8945 1110.38 32.8945H1079.75C1079.36 32.8945 1078.98 32.9415 1078.62 33.03C1077.21 32.2304 1076.1 30.9628 1075.49 29.438C1075.18 28.7196 1075 27.9246 1075 27.0884C1075 27.0463 1075 27.0044 1075 26.9626C1075 26.9162 1075 26.8697 1075 26.8232C1075 22.8866 1078.19 19.6954 1082.13 19.6954C1083.49 19.6954 1084.76 20.0776 1085.85 20.7406C1087.79 17.8792 1091.08 16 1094.8 16C1098.63 16 1101.99 17.9853 1103.91 20.982C1105.07 20.1712 1106.48 19.6954 1108 19.6954C1111.94 19.6954 1115.13 22.8866 1115.13 26.8232C1115.13 26.8688 1115.13 26.9143 1115.13 26.9596C1115.13 27.0025 1115.13 27.0454 1115.13 27.0884C1115.13 27.9256 1114.95 28.7214 1114.63 29.4404C1114.03 30.9641 1112.92 32.2308 1111.51 33.0299Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M344.572 24.4214H345.297C345.298 24.4222 345.299 24.4229 345.3 24.4237H355C355.329 24.4237 355.652 24.4502 355.967 24.5012C357.923 23.335 359.459 21.5393 360.297 19.3938C360.749 18.3631 361 17.2242 361 16.0267C361 15.9681 360.999 15.9098 360.998 15.8515C361 15.7833 361 15.7148 361 15.6463C361 9.95628 356.388 5.34364 350.698 5.34364C348.501 5.34364 346.465 6.03116 344.793 7.20276C342.011 2.87035 337.15 0 331.619 0C326.237 0 321.491 2.71691 318.675 6.85384C317.111 5.89585 315.271 5.34364 313.303 5.34364C307.613 5.34364 303 9.95628 303 15.6463C303 15.7131 303.001 15.7797 303.002 15.8462C303.001 15.9062 303 15.9664 303 16.0267C303 17.2259 303.251 18.3664 303.705 19.3983C304.543 21.5418 306.079 23.3358 308.033 24.5012C308.348 24.4502 308.671 24.4237 309 24.4237H344.57C344.57 24.4229 344.571 24.4222 344.572 24.4214Z"
          fill="white"
        />
      </svg>
    </>
  )
}
