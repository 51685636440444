import React, { FunctionComponent } from 'react'
import {
  benefitToReadableString,
  dgrStatusToReadableString,
  geoFootprintToReadableString,
  legalStructureToReadableString
} from './forms/enumsToReadableStrings'
import { THEME } from '../../lib'

const { stateShort } = THEME

type Props = {
  original: Charity | Campaign
  changes: Charity | Campaign
}

function readableField(inputString: string) {
  const parts = inputString.split('_')

  if (parts.length === 1) {
    return parts[0]
  }

  return parts
    .slice(1)
    .join(' ')
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
}

function readableValue(inputValue: unknown): string {
  if (typeof inputValue === 'boolean') {
    return inputValue ? 'Yes' : 'No'
  }

  const validDate = Date.parse(inputValue as string)
  if (!isNaN(validDate)) {
    return new Date(validDate).toLocaleDateString()
  }

  const inputString = inputValue as string

  return (
    legalStructureToReadableString[stateShort][inputString] ||
    geoFootprintToReadableString[inputString] ||
    dgrStatusToReadableString[inputString] ||
    benefitToReadableString[inputString] ||
    inputString
  )
}

export const Diff: FunctionComponent<Props> = ({ original, changes }) => {
  const numChanges = Object.keys(changes).length

  return (
    <>
      <div className="mb-2 -mt-2 pl-4 text-sm text-storm">{numChanges} changes</div>

      <ul className="flex flex-col space-y-4">
        {Object.keys(changes).map(key => {
          const keyName = readableField(key)

          return (
            <li key={key} className="grid grid-cols-7 gap-x-4 [&:nth-child(odd)]:bg-bg-color p-4">
              <h2 className="col-span-7 text-lg mb-2 capitalize">{keyName}</h2>
              {/* @ts-ignore */}
              <div className="col-span-3 line-through">{readableValue(original[key]) || 'No value'}</div>
              <div className="col-span-1 text-center">-</div>
              {/* @ts-ignore */}
              <div className="col-span-3">{readableValue(changes[key]) || 'No value'}</div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
