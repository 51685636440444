import React, { FunctionComponent } from 'react'

type Props = {
  status: RequestStatus
}

export const SavingStatus: FunctionComponent<Props> = ({ status }) => {
  const containerClass =
    status === 'loading'
      ? 'container-saving'
      : status === 'success'
        ? 'container-saved'
        : status === 'failed'
          ? 'container-failure'
          : ''

  const checkmarkClass = status === 'success' ? 'check-icon-saved' : ''
  const errorClass = status === 'failed' ? 'error-icon-failure' : ''

  return (
    <div className="check">
      <div className={`container ${containerClass}`}>
        <div className={`check-icon ${checkmarkClass}`}></div>
        <div className={`error-icon ${errorClass}`}></div>
      </div>
    </div>
  )
}
