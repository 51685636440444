import React from 'react'

export const CampaignCardSkeleton = () => (
  <>
    <div className="sr-only" aria-busy="true">
      Loading campaign...
    </div>
    <div className="card p-4" aria-hidden="true">
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-2">
          <div className="skeleton-paragraph w-32" />
          <div className="skeleton-paragraph w-44" />
        </div>

        <div className="flex flex-col space-y-2">
          <div className="skeleton-paragraph w-10" />
          <div className="skeleton-heading w-20" />
        </div>

        <div className="flex flex-col space-y-2">
          <div className="skeleton-paragraph w-10" />
          <div className="skeleton-heading w-20" />
        </div>
      </div>
    </div>
  </>
)
