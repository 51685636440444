import { CampaignsTotalRaised } from './campaign/CampaignsTotalRaised'
import { CampaignsRaisedChart } from './campaign/CampaignsRaisedChart'
import React, { FunctionComponent } from 'react'

export const CampaignStatistics: FunctionComponent<{ campaignsWithStatistics: CampaignWithStatistics[] }> = ({
  campaignsWithStatistics
}) => {
  return (
    <>
      <h2 className="lg:col-span-12 text-xl ml-4">Your totals</h2>

      <div className="lg:col-span-3">
        <CampaignsTotalRaised campaignsWithStatistics={campaignsWithStatistics} />
      </div>

      <div className="lg:col-span-5">
        <CampaignsRaisedChart
          campaignsWithStatistics={campaignsWithStatistics.filter(
            ({ campaign }) => campaign.status !== 'NEW' && campaign.status !== 'ACTIVE'
          )}
        />
      </div>
    </>
  )
}
