import React, { useEffect, useMemo, useState } from 'react'
import { CharityFormSkeleton } from './common/CharityFormSkeleton'
import { CharityForm } from './common/CharityForm'
import { auth } from '../../firebase'
import { updateCharity } from '../../services'
import { deleteDraft, getOrCreateDraft } from '../../services/firestore.service'
import { logError } from '@tomra/datadog-browser-logging'
import { useNavigate, useParams } from 'react-router-dom'
import { CheckmarkIcon, Modal } from '../../components/shared'
import { ErrorCard } from '../../components/shared/ErrorCard'
import { DisabledCharity } from '../dashboard/common/DisabledCharity'
import { Diff } from '../../components/shared/Diff'
import { useCharity } from '../../context/CharityContextProvider'
import { flattenObject } from '../../lib/charity'
import { findStateDifference } from '../../lib/stateHelpers'

export const UpdateCharityPage = () => {
  const adminId = useMemo(() => auth.currentUser!.uid, [])
  const { charityId } = useParams() as { charityId: string }
  const navigate = useNavigate()
  const [draft, setDraft] = useState<Draft>()
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [submitError, setSubmitError] = useState('')
  const [showPendingChanges, setShowPendingChanges] = useState(false)
  const [charityWithPendingChanges, setCharityWithPendingChanges] = useState<Charity>()
  const [charityChanges, setCharityChanges] = useState()

  const { charityRequestStatus, charity, charityPendingChanges } = useCharity()

  useEffect(() => {
    if (charityRequestStatus.charity !== 'loaded' || charityRequestStatus.pendingChanges !== 'loaded') {
      return
    }

    const originalWithChanges = { ...charity, ...charityPendingChanges } as Charity
    setCharityWithPendingChanges(originalWithChanges)

    getOrCreateDraft(adminId, originalWithChanges)
      .then(data => {
        setDraft(data)
      })
      .catch(error => {
        logError(new Error('Failed to get or create draft'), error)
      })
  }, [charity, charityPendingChanges])

  const updateExistingCharity = async (charity: Charity) => {
    try {
      setSubmitRequestStatus('loading')

      await updateCharity({
        id: charityId,
        organization: charity.organization,
        bankingData: charity.bankingData,
        primaryContact: charity.primaryContact,
        secondaryContact: charity.secondaryContact || null
      })

      await deleteDraft(adminId)

      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 412) {
        setSubmitRequestStatus('preConditionFailed')
        setSubmitError(error?.body?.detail)
      } else {
        setSubmitRequestStatus('failed')
        logError(new Error('Failed to update charity'), error)
      }
    }
  }

  useEffect(() => {
    if (!charityWithPendingChanges) {
      return
    }

    const changes = findStateDifference(charity, charityPendingChanges)
    setCharityChanges(changes)
  }, [charityWithPendingChanges])

  return charity?.status === 'DISABLED' ? (
    <DisabledCharity />
  ) : submitRequestStatus === 'failed' || charityRequestStatus.charity === 'failed' ? (
    <ErrorCard />
  ) : charityWithPendingChanges && draft ? (
    <>
      {charity && charityChanges && Object.keys(charityChanges)?.length > 0 && (
        <>
          <div className="alert warning w-2/3 flex flex-col space-y-2 items-start mb-6">
            <h2>You have changes waiting for review</h2>
            <p>
              Any new changes you submit add to/overwrite the{' '}
              <button className="underline" onClick={() => setShowPendingChanges(true)}>
                existing
              </button>{' '}
              changes.
            </p>
          </div>
          <Modal isOpen={showPendingChanges}>
            <div className="dialog">
              <div className="dialog-header">
                <h2 className="text-2xl mb-4">Changes pending approval</h2>
              </div>
              <div className="dialog-content max-h-[50vh] overflow-y-scroll">
                <Diff original={flattenObject(charity)} changes={flattenObject(charityChanges)} />
              </div>
              <div className="dialog-footer">
                <button className="btn outlined" onClick={() => setShowPendingChanges(false)}>
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}

      <CharityForm
        original={charityWithPendingChanges}
        savedDraft={draft as Draft}
        onSubmit={updateExistingCharity}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
        formHeading={
          <>
            <h1 className="text-3xl mb-4">Edit charity</h1>
            <p className="prose">
              You can update your charity information. Changes will be pending until reviewed by TOMRA.
            </p>
          </>
        }
      />

      <Modal isOpen={submitRequestStatus === 'success'}>
        <div className="dialog">
          <div className="card flex flex-col items-center space-y-6 py-10 px-6">
            <CheckmarkIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />
            <div className="text-center">
              <h1 className="text-3xl mb-2">Excellent!</h1>
              <p>Your changes has been submitted for review.</p>
            </div>
            <button className="btn" onClick={() => navigate(`/`)}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <CharityFormSkeleton />
  )
}
