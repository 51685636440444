import React, { FunctionComponent } from 'react'
import { THEME } from '../../lib'
import { Link, NavLink } from 'react-router-dom'
import { auth } from '../../firebase'
import { LogoutIcon } from '../shared'

type Props = {
  isAuthenticated: 'yes' | 'no' | 'toBeDecided'
  logout: () => void
}

export const LargeScreenNavBar: FunctionComponent<Props> = ({ isAuthenticated, logout }) => {
  return (
    <nav className="hidden lg:block menu">
      <ul>
        <li>
          <Link to="/" className="flex items-center w-full">
            <img src={`themes/${THEME.logoUrl}`} className="h-[3rem]" alt={`${THEME.displayName} logo`} />
            <span className="text-lg ml-4">Charity</span>
          </Link>
        </li>

        {isAuthenticated === 'yes' && (
          <>
            <li>
              <NavLink to="/">Dashboard</NavLink>
            </li>

            <li>
              <NavLink to="/tos">Terms and Conditions</NavLink>
            </li>

            <li className="!col-[10_/_span_3]">
              <NavLink to="/profile">{auth.currentUser?.displayName || auth.currentUser?.email}</NavLink>

              <button className="btn-icon px-4" aria-label="Log out" onClick={logout}>
                <LogoutIcon width="2rem" height="2rem" />
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}
