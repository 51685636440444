import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { submitCampaign } from '../../services'
import { logError } from '@tomra/datadog-browser-logging'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CampaignForm } from '../../components/campaigns/CampaignForm'
import { CheckmarkIcon, Modal } from '../../components/shared'
import { useCampaign } from '../../context/CampaignContextProvider'
import { CampaignFormSkeleton } from './CampaignFormSkeleton'

export const CreateCampaignPage = () => {
  const { charityId } = useParams() as { charityId: string }
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [submitError, setSubmitError] = useState('')
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [campaignToCopy, setCampaignToCopy] = useState<Campaign | null>(null)
  const [isReady, setIsReady] = useState(false)

  const { latestCampaign } = useCampaign(charityId)

  useEffect(() => {
    const idToCopy = searchParams.get('copy')

    if (idToCopy && latestCampaign) {
      const { name, description, startTime, endTime, financialTarget, engagementPlan } = latestCampaign.campaign
      setCampaignToCopy({
        name,
        description,
        startTime,
        endTime,
        financialTarget,
        engagementPlan,
        status: 'NEW'
      } as Campaign)
    }

    setIsReady(true)
  }, [searchParams])

  const createNewCampaign = async (campaign: Campaign) => {
    try {
      setSubmitError('')

      const newCampaignId = uuidv4()
      setSubmitRequestStatus('loading')
      await submitCampaign(charityId, { ...campaign, campaignId: newCampaignId })
      setSubmitRequestStatus('success')
    } catch (error: any) {
      if (error.status === 400) {
        setSubmitError(error.body.detail ? error.body.detail : 'Something went wrong. Please try again.')
      } else {
        setSubmitError('Something went wrong. Please try again.')
      }

      setSubmitRequestStatus('failed')
      logError(new Error('Failed to create new campaign'), error)
    }
  }

  return isReady ? (
    <>
      <CampaignForm
        original={campaignToCopy}
        onSubmit={createNewCampaign}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
      />

      <Modal isOpen={submitRequestStatus === 'success'}>
        <div className="dialog card flex flex-col items-center p-6 space-y-6">
          <CheckmarkIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />
          <div className="text-center">
            <h1 className="text-3xl mb-4">Great!</h1>
            <p className="max-w-[50ch]">
              Your campaign has been submitted and should be processed within 10 business days.
            </p>
          </div>
          <button className="btn" onClick={() => navigate('/')}>
            Close
          </button>
        </div>
      </Modal>
    </>
  ) : (
    <CampaignFormSkeleton />
  )
}
