import React from 'react'

export const ErrorCard = () => (
  <div className="alert warning max-w-screen-sm mx-auto">
    <h1>Something went wrong on our side</h1>

    <p>
      We are sorry for the inconvenience. Please{' '}
      <a href="/" className="no-underline border-current border-b-2">
        click here to reload the page
      </a>
    </p>
  </div>
)
