import React, { FunctionComponent } from 'react'
import charityGraphIllustration from '../../../images/charity-graph-illustration.svg'
import { Link } from 'react-router-dom'

type Props = {
  charityId: string
  charityStatus: Charity['status']
  latestCampaignStatus: Campaign['status'] | undefined
}

export const CharityState: FunctionComponent<Props> = ({ charityId, charityStatus, latestCampaignStatus }) => {
  const noCampaign = latestCampaignStatus === undefined
  const allPending = charityStatus === 'NEW' && latestCampaignStatus === 'NEW'
  const charityPending = charityStatus === 'NEW' && noCampaign
  const campaignPending = latestCampaignStatus === 'NEW'
  const latestCampaignHasEnded = latestCampaignStatus === 'EXPIRED'

  const showSetUpCampaignButton = noCampaign || latestCampaignHasEnded

  return (
    <div className="col-span-12 flex flex-col items-center justify-center space-y-6 mt-[5rem]">
      <div className="w-[15rem]">
        <img src={charityGraphIllustration} alt="Charity graph illustration" />
      </div>

      {allPending ? (
        <AllPendingStatus />
      ) : charityPending ? (
        <CharityPendingStatus />
      ) : campaignPending ? (
        <CampaignPendingStatus />
      ) : (
        <DefaultCharityStatus />
      )}

      {showSetUpCampaignButton && (
        <Link to={`/charity/${charityId}/campaign`} className="btn primary">
          Set up a campaign
        </Link>
      )}
    </div>
  )
}

const DefaultCharityStatus = () => (
  <>
    <h1 className="text-3xl">Welcome to your charity page</h1>
  </>
)

const CharityPendingStatus = () => (
  <>
    <h1 className="text-3xl">Welcome to your charity page</h1>
    <p>Your charity is pending approval.</p>
  </>
)

const CampaignPendingStatus = () => (
  <>
    <h1 className="text-3xl">Welcome to your charity page</h1>
    <p>Your campaign is pending approval.</p>
  </>
)

const AllPendingStatus = () => (
  <>
    <h1 className="text-3xl">Welcome to your charity page</h1>
    <p>Your charity and campaign is pending approval.</p>
  </>
)
