export const firebaseAuthErrorMap: { [key: string]: { title: string; message: string } } = {
  'auth/invalid-email': {
    title: 'Oops! Wrong e-mail',
    message: 'The email does not match the one we sent the login link to. Please try again.'
  },
  'auth/invalid-action-code': {
    title: 'The link is not valid',
    message:
      "This may happen if the link has expired or you've already clicked it. Check if the page has opened in another browser window, or try sending another link to your e-mail."
  },
  'auth/expired-action-code': {
    title: 'The link has expired',
    message: 'Try sending another link to your e-mail.'
  },
  'auth/firebase-app-check-token-is-invalid.': {
    title: 'Problems verifying your device',
    message: 'Your device did not pass our security checks. Please try another device, or contact customer support.'
  }
}
