const { ENV: ENVIRONMENT, STAGE, CONFIG_BY_STAGE, DATADOG_CONFIG, VERSION } = window.__ENVIRONMENT__
const { API_HOST, FIREBASE_CONFIG, APP_CHECK_KEY } = CONFIG_BY_STAGE[STAGE].CONFIG_BY_ENV[ENVIRONMENT]
const { AMPLITUDE_CONFIG } = CONFIG_BY_STAGE[STAGE]

const THEME = window.__THEME__

export {
  ENVIRONMENT,
  API_HOST,
  STAGE,
  FIREBASE_CONFIG,
  THEME,
  APP_CHECK_KEY,
  DATADOG_CONFIG,
  VERSION,
  AMPLITUDE_CONFIG
}
