import React, { useEffect } from 'react'
import anime from 'animejs'
import { logError } from '@tomra/datadog-browser-logging'

export const AnimatedPaperPlane = () => {
  useEffect(() => {
    try {
      const path = anime.path('#path g path')

      anime({
        targets: '#paperplane',
        translateX: path('x'),
        translateY: path('y'),
        easing: 'easeInSine',
        direction: 'reverse',
        duration: 1500
      })
    } catch (error) {
      logError(new Error('Unable to animate paperplane'), error as Error)
    }
  }, [])

  return (
    <div className="relative max-w-screen-sm h-[15rem] my-[4rem] mx-auto flex justify-center align-center animate-fadeIn">
      <svg
        id="paperplane"
        className="absolute left-[8rem] top-0 z-[1]"
        width="102"
        height="102"
        viewBox="0 0 102 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.11446 65.4225C5.9597 65.4224 3.1865 63.3328 2.31669 60.3003C1.44689 57.2678 2.69086 54.0259 5.36596 52.3537L83.9187 3.24921C86.3 1.75899 89.3378 1.82047 91.6568 3.40582C93.9758 4.99117 95.1358 7.7994 94.6117 10.5592L79.3117 92.0445C78.7929 94.7989 76.6963 96.9871 73.9665 97.623C71.2367 98.259 68.389 97.2228 66.7062 94.9812L44.5637 65.4225H9.11446Z"
          fill="#F26522"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.5637 65.4225L33.9387 65.4225V87.3992C33.9386 90.0192 35.3878 92.4243 37.7042 93.6485C40.0206 94.8726 42.8242 94.7149 44.9887 93.2387L58.4952 84.0332L44.5637 65.4225Z"
          fill="#F26522"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M44.5595 65.4225L91.6495 3.41496Z" fill="#F26522" />
        <path
          d="M44.5637 65.4225L66.7062 94.9812C68.389 97.2228 71.2367 98.259 73.9665 97.623C76.6963 96.9871 78.7929 94.7989 79.3117 92.0445L94.6117 10.5592C95.1358 7.7994 93.9758 4.99117 91.6568 3.40582C89.3378 1.82047 86.3 1.75899 83.9187 3.24921L5.36596 52.3537C2.69086 54.0259 1.44689 57.2678 2.31669 60.3003C3.1865 63.3328 5.9597 65.4224 9.11446 65.4225H44.5637ZM44.5637 65.4225L33.9387 65.4225V87.3992C33.9386 90.0192 35.3878 92.4243 37.7042 93.6485C40.0206 94.8726 42.8242 94.7149 44.9887 93.2387L58.4952 84.0332L44.5637 65.4225ZM44.5595 65.4225L91.6495 3.41496"
          stroke="#071E36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        id="path"
        width="296"
        height="102"
        viewBox="0 0 296 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-1/2 -translate-y-1/2"
      >
        <g filter="url(#filter0_d_201_220)">
          <path
            d="M290 2C281.5 16.5 266.742 27.9778 256 33.5556C240.411 41.6496 226.659 48.3575 210.361 50.9024M210.361 50.9024C205.805 51.6139 201.05 52 196 52C183.6 52 179.5 53.5 170 38C166 25 173.735 12 192 12C202.821 12 216 14.4234 216 29C216 38.1858 214.25 44.9145 210.361 50.9024ZM210.361 50.9024C207.634 55.102 203.855 58.9371 198.889 63C174 87.5 122.885 72.5 99.5 72.5C59.5183 72.5 45.9012 92 6 92"
            stroke="#071E36"
            strokeWidth="3"
            strokeLinecap="round"
            strokeDasharray="6 6"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_201_220"
            x="0.5"
            y="0.499756"
            width="295"
            height="101"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_220" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_220" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  )
}
