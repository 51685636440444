import React from 'react'
import { THEME } from '../../../lib'

const { supportEmail } = THEME

export const DisabledCharity = () => {
  return (
    <div className="alert warning">
      <h1>Your organisation has been deactivated</h1>
      <p>
        For any inquiries, please contact us at{' '}
        <a className="no-underline border-current border-b-2" href={`mailto:${supportEmail}`}>
          {supportEmail}
        </a>
      </p>
    </div>
  )
}
