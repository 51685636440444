import React from 'react'

export const CharityFormSkeleton = () => {
  return (
    <div className="max-w-screen-2xl mx-auto my-md md:grid md:grid-cols-12 md:gap-x-6">
      <div className="col-span-8 space-y-6">
        <div className="card px-4 py-8">
          <div className="flex flex-col items-start space-y-4">
            <div className="w-2/3 skeleton-heading" />
            <div className="w-1/2 skeleton-paragraph" />
            <div className="w-2/3 skeleton-paragraph" />
            <div className="w-2/4 skeleton-paragraph" />
            <div className="w-3/5 skeleton-paragraph" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>

        <div className="card p-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="w-8 h-8 skeleton-circle" />
            <div className="flex-1">
              <div className="w-2/4 skeleton-heading" />
            </div>
            <div className="w-8 h-8 skeleton-circle" />
          </div>
        </div>
      </div>

      <div className="col-span-4">
        <div className="card p-4">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-10 h-10 skeleton-circle" />
            <div className="w-20 skeleton-paragraph" />
            <div className="w-2/3 skeleton-paragraph" />
          </div>
        </div>
      </div>
    </div>
  )
}
