import React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

type Props = {
  value: Date
  onChange: (date: Date) => void
  id?: string
  max?: Date
  min?: Date
  required?: boolean
  disabled?: boolean
  className?: string
  onBlur?: () => void
}

export const Datepicker = ({ value, onChange, id, max, min, required, disabled, className, onBlur }: Props) => (
  <DatePicker
    id={id}
    maxDate={max}
    minDate={min}
    selected={new Date(value)}
    onChange={(date: Date) => onChange(date)}
    required={required}
    disabled={disabled}
    aria-disabled={disabled}
    onBlur={onBlur}
    className={className}
    dateFormat="dd/MM/yyyy"
    placeholderText="Select a date"
  />
)
