import { getToken } from 'firebase/app-check'
import { appCheck } from '../firebase'
import { jwtDecode } from 'jwt-decode'
import { logInfo } from '@tomra/datadog-browser-logging'

const tokenHasExpired = (token: string): boolean => {
  const decoded = jwtDecode(token)
  if (!decoded.exp) {
    return true
  }
  const currentTime = Math.floor(Date.now() / 1000)
  return decoded.exp < currentTime
}
const getAppCheckToken = async (): Promise<string> => {
  const appCheckTokenResponse = await getToken(appCheck, false)
  let token = appCheckTokenResponse?.token

  if (!token || tokenHasExpired(token)) {
    logInfo(
      tokenHasExpired(token)
        ? 'getToken: AppCheck token has expired, fetching a new one'
        : 'getToken: No AppCheck token found, fetching a new one'
    )

    const newAppCheckTokenResponse = await getToken(appCheck, true)
    token = newAppCheckTokenResponse?.token
  }

  return token || ''
}

export const getAppCheckConfig = () => ({ getAppCheckToken })
