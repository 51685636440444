import React, { useEffect, useState } from 'react'
import { updateCampaign } from '../../services'
import { logError } from '@tomra/datadog-browser-logging'
import { useNavigate, useParams } from 'react-router-dom'
import { useCampaign } from '../../context/CampaignContextProvider'
import { CampaignForm } from '../../components/campaigns/CampaignForm'
import { CampaignFormSkeleton } from './CampaignFormSkeleton'
import { CheckmarkIcon, Modal } from '../../components/shared'
import { Diff } from '../../components/shared/Diff'
import { findStateDifference } from '../../lib/stateHelpers'

export const UpdateCampaignPage = () => {
  const { charityId } = useParams() as { charityId: string }
  const navigate = useNavigate()

  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatus>('initial')
  const [showPendingChanges, setShowPendingChanges] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [latestCampaignChanges, setLatestCampaignChanges] = useState()

  const { latestCampaign, campaignRequestStatus } = useCampaign(charityId)

  const updateExistingCampaign = async (campaign: Campaign) => {
    try {
      setSubmitError('')
      setSubmitRequestStatus('loading')
      await updateCampaign(charityId, campaign)
      setSubmitRequestStatus('success')
    } catch (error: any) {
      setSubmitRequestStatus('failed')
      logError(new Error('Failed to update new campaign'), error)

      if (error.status === 400) {
        setSubmitError(error.body.detail ? error.body.detail : 'Something went wrong. Please try again.')
      } else {
        setSubmitError('Something went wrong. Please try again.')
      }
    }
  }

  useEffect(() => {
    if (!latestCampaign) {
      return
    }

    const changes = findStateDifference(latestCampaign.campaign, latestCampaign.pendingChanges)
    setLatestCampaignChanges(changes)
  }, [latestCampaign])

  if (campaignRequestStatus.campaign === 'failed') {
    return <h1 className="alert danger">Something went wrong. Please try again later</h1>
  }

  return campaignRequestStatus.campaign === 'loaded' && campaignRequestStatus.pendingChanges === 'loaded' ? (
    <>
      {latestCampaign && latestCampaignChanges && Object.keys(latestCampaignChanges).length > 0 && (
        <>
          <div className="alert warning max-w-screen-sm flex flex-col space-y-2 items-start mb-6 mx-auto">
            <h2>You have changes waiting for review</h2>
            <p>
              Any new changes you submit add to/overwrite the{' '}
              <button className="underline" onClick={() => setShowPendingChanges(true)}>
                existing
              </button>{' '}
              changes.
            </p>
          </div>
          <Modal isOpen={showPendingChanges}>
            <div className="dialog">
              <div className="dialog-header">
                <h2 className="text-2xl mb-4">Changes pending approval</h2>
              </div>
              <div className="dialog-content max-h-[50vh] overflow-y-scroll">
                <Diff original={latestCampaign.campaign} changes={latestCampaignChanges} />
              </div>
              <div className="dialog-footer">
                <button className="btn outlined" onClick={() => setShowPendingChanges(false)}>
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}

      <CampaignForm
        original={{ ...latestCampaign?.campaign, ...latestCampaign?.pendingChanges } as Campaign}
        onSubmit={updateExistingCampaign}
        submitRequestStatus={submitRequestStatus}
        submitError={submitError}
      />

      <Modal isOpen={submitRequestStatus === 'success'}>
        <div className="dialog card flex flex-col items-center p-6 space-y-6">
          <CheckmarkIcon width="4rem" height="4rem" color="rgb(var(--tomra-mint-green-dark))" />
          <div className="text-center">
            <h1 className="text-3xl mb-4">Great!</h1>
            <p className="max-w-[50ch]">
              Your changes has been submitted and should be processed within 10 business days.
            </p>
          </div>
          <button className="btn" onClick={() => navigate('/')}>
            Close
          </button>
        </div>
      </Modal>
    </>
  ) : (
    <CampaignFormSkeleton />
  )
}
