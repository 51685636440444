import { useReducer } from 'react'
import { charityInit } from '../initialData'
import { mergeStateObjects } from '../lib/stateHelpers'

type CharityFormFieldAction =
  | {
      type: 'UPDATE_LOGO_URL'
      payload: string
    }
  | {
      type: 'UPDATE_ORGANIZATION_FIELDS'
      payload: Partial<Charity['organization']>
    }
  | {
      type: 'UPDATE_BANKING_DATA_FIELDS'
      payload: Partial<Charity['bankingData']>
    }
  | {
      type: 'UPDATE_PRIMARY_CONTACT_FIELDS'
      payload: Partial<Charity['primaryContact']>
    }
  | {
      type: 'UPDATE_SECONDARY_CONTACT_FIELDS'
      payload: Partial<Charity['secondaryContact']>
    }

const charityFieldsReducer = (state: Charity, { type, payload }: CharityFormFieldAction): Charity => {
  switch (type) {
    case 'UPDATE_LOGO_URL':
      return {
        ...state,
        logoUrl: payload
      }
    case 'UPDATE_ORGANIZATION_FIELDS':
      return {
        ...state,
        organization: {
          ...state.organization,
          ...payload
        }
      }
    case 'UPDATE_BANKING_DATA_FIELDS':
      return {
        ...state,
        bankingData: {
          ...state.bankingData,
          ...payload
        }
      }
    case 'UPDATE_PRIMARY_CONTACT_FIELDS':
      return {
        ...state,
        primaryContact: {
          ...state.primaryContact,
          ...payload
        }
      }
    case 'UPDATE_SECONDARY_CONTACT_FIELDS':
      return {
        ...state,
        secondaryContact: {
          ...state.secondaryContact,
          ...payload
        }
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export const useCharityFormFields = (charity: Charity | null, savedDraftCharity: Draft['charity']) => {
  const initialState = charity
    ? mergeStateObjects(charity, savedDraftCharity)
    : mergeStateObjects(charityInit, savedDraftCharity)

  const [state, dispatch] = useReducer(charityFieldsReducer, initialState)

  function updateLogoUrl(payload: string) {
    dispatch({ type: 'UPDATE_LOGO_URL', payload })
  }

  function updateOrganization(payload: Partial<Charity['organization']>) {
    dispatch({ type: 'UPDATE_ORGANIZATION_FIELDS', payload })
  }

  function updateBankingData(payload: Partial<Charity['bankingData']>) {
    dispatch({ type: 'UPDATE_BANKING_DATA_FIELDS', payload })
  }

  function updatePrimaryContact(payload: Partial<Charity['primaryContact']>) {
    dispatch({ type: 'UPDATE_PRIMARY_CONTACT_FIELDS', payload })
  }

  function updateSecondaryContact(payload: Partial<Charity['secondaryContact']>) {
    dispatch({ type: 'UPDATE_SECONDARY_CONTACT_FIELDS', payload })
  }

  return {
    formValues: state,
    updateLogoUrl,
    updateOrganization,
    updateBankingData,
    updatePrimaryContact,
    updateSecondaryContact
  }
}
