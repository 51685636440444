import { fetch as _fetch, fetchData as _fetchData, fetchRawResponse as _fetchRaw } from '@tomra/client-side-http-client'
import { getAppCheckConfig } from './appCheck'
import { THEME } from './environment'
import { auth } from '../firebase'

const { stateShort } = THEME

export const authenticatedFetchData = (url: string, options?: RequestInit) =>
  _fetchData(
    url,
    { ...options, headers: { 'Charity-State': stateShort, ...options?.headers } },
    {
      getToken: () => (auth.currentUser ? auth.currentUser.getIdToken() : ''),
      ...getAppCheckConfig()
    }
  )

export const authenticatedFetchRaw = (url: string, options?: RequestInit) =>
  _fetchRaw(
    url,
    { ...options, headers: { 'Charity-State': stateShort, ...options?.headers } },
    {
      getToken: () => (auth.currentUser ? auth.currentUser.getIdToken() : ''),
      ...getAppCheckConfig()
    }
  )

export const enrichedFetch = (url: string, options?: RequestInit) => _fetch(url, options, getAppCheckConfig())
