import React, { ReactNode } from 'react'
import { TermsAndConditionsNSWFull } from './schemes/TermsAndConditionsNSWFull'
import { TermsAndConditionsVICFull } from './schemes/TermsAndConditionsVICFull'
import { TermsAndConditionsTASFull } from './schemes/TermsAndConditionsTASFull'
import { THEME } from '../../lib'

const { stateShort } = THEME

export const TermsAndConditionsPage = () => {
  const tosMap: { [key: string]: ReactNode } = {
    NSW: <TermsAndConditionsNSWFull />,
    VIC: <TermsAndConditionsVICFull />,
    TAS: <TermsAndConditionsTASFull />
  }

  return <>{tosMap[stateShort]}</> || null
}
